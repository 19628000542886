@import url("https://fonts.googleapis.com/css2?family=Alata&display=swap");
@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url(./components/fonts/Gilroy-Light.otf) format('otf');
}
body {
  margin: 0;
  font-family: "Alata", sans-serif;
}
html {
  scroll-behavior: smooth;
}
* {
  outline: none;
}
.toaster {
  font-family: "Alata", sans-serif;
  font-size: 15px;
  line-height: 25px;
  z-index: 9999;
}
.container {
  max-width: 1340px;
  margin: 0 auto;
}
.header {
  position: fixed;
  z-index: 10000;
  width: 100%;
  transition: 0.4s;
  border-bottom: 1px solid transparent;
}
.header.scrolled {
  background-color: hsla(0, 0%, 100%, 0.95);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  border-bottom: 1px solid #e4f1ff;
  color: #0f0f0f;
}
.header .nav-list__link.dark {
  color: #0f0f0f;
}
.header .nav {
  padding-top: 16px;
  padding-bottom: 16px;
  align-items: center;
}
.header .nav,
.header .nav-list {
  display: flex;
  justify-content: space-between;
}
.header .logo {
  display: flex;
  align-items: center;
}
.header .nav-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.header .nav,
.header .nav-list {
  display: flex;
  justify-content: space-between;
}
.header .nav-list__item {
  margin-right: 88px;
}
.header .nav-list__link {
  margin: 0;

  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: #fff;
  text-decoration: none;
  position: relative;
  transition: 0.4s;
  display: inline-flex;
}
.header .nav-list__link:after {
  content: "";
  margin: 0 auto;
  width: 10px;
  height: 11px;
  display: block;
  position: absolute;
  bottom: -18px;
  left: 0;
  right: 0;
  transition: 0.4s;
  opacity: 0;
  transform: translateY(-100%);
  background-repeat: no-repeat;
}
.header .nav-list__link:hover:after {
  transform: none;
  opacity: 1;
}
.header .nav__button {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #fff;
  background-color: #1958e3;
  padding: 11px 24px;
  border: 1px solid transparent;
  text-decoration: none;
  transition: 0.4s;
  cursor: pointer;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}
.home {
  padding-top: 191px;
  position: relative;
  overflow: hidden;
  background-size: cover;
}
.home__block {
  z-index: 10;
  position: inherit;
}
.home__title {
  font-weight: 800;
  font-size: 80px;
  line-height: 110%;
  color: #fff;
  max-width: 905px;
  margin: 0 0 24px;
}
.home-link,
.home__text {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
}

.home-link {
  margin: 0 0 103px;
  padding: 17px 20px 17px 24px;
  display: flex;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid #fff;
  box-sizing: border-box;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background-color: hsla(0, 0%, 100%, 0.01);
  cursor: pointer;
  transition: 0.4s;
}
.home-link__svg {
  margin-left: 14px;
}
.home-card {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  z-index: 10;
  position: inherit;
}
.home-card__item {
  padding: 20px 0 20px 64px;
  border-top: 1px solid hsla(0, 0%, 100%, 0.3);
  transition: 0.4s;
  position: relative;
}
.home-card__item:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 0.4s;
}
.home-card__item:first-child,
.home-card__item:nth-child(2),
.home-card__item:nth-child(3) {
  border-right: 1px solid hsla(0, 0%, 100%, 0.3);
}
.home-card__title {
  font-weight: 700;
  font-size: 64px;
  line-height: 70px;
  color: transparent;
  letter-spacing: -0.015em;
  margin: 0 0 9px;
  transition: 0.4s;
  position: relative;
}
.home-card__subtitle,
.home-card__title {
  z-index: 2;
}
.home-card__item:first-child .home-card__title {
  background: linear-gradient(90deg, #da22ff, #9733ee);
  -webkit-background-clip: text;
}
.home-card__item:nth-child(2) .home-card__title {
  background: linear-gradient(90deg, #ec008c, #fc6767);
  -webkit-background-clip: text;
}
.home-card__item:nth-child(3) .home-card__title {
  background: linear-gradient(90deg, #e65c00, #f9d423);
  -webkit-background-clip: text;
}
.home-card__item:nth-child(4) .home-card__title {
  background: linear-gradient(90deg, #0052d4, #4364f7 50%, #6fb1fc);
  -webkit-background-clip: text;
}
.home-card__item:first-child .home-card__title_show,
.home-card__item:nth-child(2) .home-card__title_show,
.home-card__item:nth-child(3) .home-card__title_show,
.home-card__item:nth-child(4) .home-card__title_show {
  -webkit-text-fill-color: #fff;
  position: absolute;
  left: 0;
  color: #fff;
  transition: 0.4s;
  opacity: 0;
}
.home-card__subtitle {
  margin: 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #fff;
  max-width: 191px;
  position: inherit;
}
.home-card__subtitle,
.home-card__title {
  z-index: 2;
}
.home-card__item:hover:after {
  opacity: 1;
}
.home-card__item:first-child:after {
  background: linear-gradient(90deg, #da22ff, #9733ee);
}
.home-card__item:nth-child(2):after {
  background: linear-gradient(90deg, #ec008c, #fc6767);
}
.home-card__item:nth-child(3):after {
  background: linear-gradient(90deg, #e65c00, #f9d423);
}
.home-card__item:nth-child(4):after {
  background: linear-gradient(90deg, #0052d4, #4364f7 50%, #6fb1fc);
}

.home-video,
.home-video:before {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.home-video__item {
  position: absolute;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.services {
  padding-top: 88px;
  background-repeat: no-repeat;
  background-position: left 122%;
}
.services__wrapper {
  position: relative;
}
.services__title {
  margin: 0;

  font-weight: 700;
  font-size: 64px;
  line-height: 70px;
  color: #0f0f0f;
  letter-spacing: -0.015em;
  max-width: 324px;
  position: absolute;
  opacity: 1;
  left: 0;
  transition: 1.2s;
  transition-delay: 0.1s;
}
.services__text {
  margin: 0;

  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #4b4d51;
  letter-spacing: 0.05em;
  max-width: 405px;
  position: absolute;
  top: 250px;
  opacity: 1;
  left: 0;
  transition: 1.2s;
}
.services__block {
  margin-bottom: 79px;
  display: grid;
  align-items: center;
  justify-content: flex-end;
  grid-template-columns: auto auto 105px;
  grid-column-gap: 8px;
}
.services__arrow {
  cursor: pointer;
}
.services__arrow:nth-of-type(2) {
  margin-right: 51px;
}
.services-step {
  margin: 0;

  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  color: #1958e3;
  text-align: right;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.services-slider {
  height: 566px;
  position: relative;
  overflow: hidden;
}
.services-slider__wrapper {
  display: flex;
  position: absolute;
  transition: 0.8s;
}
.services-slider__item {
  margin-right: 24px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding: 48px 67px 48px 40px;
  background-color: #f5f8ff;
  transition: 0.8s;
  text-decoration: none;
}
.services-slider__item.active {
  background-color: #1958e3;
  box-shadow: 0 2.31722px 1.85378px rgb(0 49 255 / 2%),
    0 5.38772px 4.31018px rgb(0 49 255 / 3%),
    0 9.67509px 7.74008px rgb(0 49 255 / 3%),
    0 16.0571px 12.8457px rgb(0 49 255 / 4%),
    0 26.4535px 21.1628px rgb(0 49 255 / 4%),
    0 46.233px 36.9864px rgb(0 49 255 / 5%), 0 100px 80px rgb(0 49 255 / 7%);
}
.services-slider__title {
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  margin: 56px 0 12px;
}
.services-slider__text,
.services-slider__title {
  color: #a9b1cc;
  transition: 0.8s;
}
.services-slider__item.active .services-slider__text,
.services-slider__item.active .services-slider__title {
  color: #fff;
}
.services-slider__text {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.05em;
  max-width: 405px;
}
.services-slider__item.active .services-slider__text,
.services-slider__item.active .services-slider__title {
  color: #fff;
}
.services-slider-more {
  margin: 0;

  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #fff;
  letter-spacing: 0.01em;
  display: flex;
  align-items: center;
  opacity: 0;
  position: absolute;
  bottom: 38px;
  transition: 0.4s;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.services-slider-more__svg {
  margin-left: 16px;
}

.stack {
  padding-top: 108px;
  padding-bottom: 110px;
  background-size: cover;
  background-repeat: no-repeat;
}
.stack__wrapper {
  display: flex;
  justify-content: space-between;
}
.stack__title {
  font-weight: 700;
  font-size: 64px;
  line-height: 70px;
  color: #fff;
  margin: 0 0 64px;
  letter-spacing: -0.015em;
  max-width: 334px;
}
.stack-list {
  padding: 0;
  margin: 0 0 93px;
}
.stack-list__item {
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: #fff;
  margin: 0 0 16px 36px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  opacity: 0.3;
  transition: 0.4s;
  cursor: pointer;
}
.stack-list__item.active {
  opacity: 1;
}
.stack-list__item:last-of-type {
  margin-bottom: 0;
}
.stack-list__item:before {
  content: "";
  width: 23px;
  height: 25px;
  position: absolute;
  left: -36px;
  display: flex;
  background-size: cover;
  transition: 0.4s;
}
.stack-list__item.active:before {
  transform: scale(1.5) translateX(-4px);
}
.stack__text {
  margin: 0;

  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  letter-spacing: 0.05em;
  max-width: 374px;
}
.stack-slider {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(262px, 1fr));
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  max-width: 834px;
}
.stack-slider__item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #0d1227;
}
.stack-slider__icon {
  height: 96px;
  -o-object-fit: none;
  object-fit: none;
  transition: 0.4s;
}
.stack-slider__icon,
.stack-slider__title {
  animation-name: opacityAnim;
  animation-duration: 1.6s;
  animation-iteration-count: 1;
}
.stack-slider__title {
  margin: 0;

  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: #fff;
}
.awards {
  padding-top: 128px;
  padding-bottom: 124px;
}
.awards__wrapper {
  display: grid;
  align-items: center;
  grid-template-columns: 406px 1fr;
  grid-column-gap: 24px;
}
.awards__subtitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #1958e3;
  margin: 0 0 12px;
}
.awards__title {
  font-weight: 700;
  font-size: 64px;
  line-height: 70px;
  color: #0f0f0f;
  margin: 0 0 24px;
  letter-spacing: -0.015em;
}
.awards__text,
.awards__title {
}
.awards__text {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #4b4d51;
  max-width: 406px;
  letter-spacing: 0.05em;
}
.awards__span {
  display: block;
  margin-top: 10px;
}

.awards-card {
  box-sizing: border-box;
  padding: 20px;
  width: 290px;
  height: 149px;
  position: absolute;
  overflow: hidden;
  transition: 0.8s;
  z-index: 0;
}
.awards__block:nth-of-type(2) {
  margin-left: auto;
  width: 600px;
  justify-content: flex-end;
  height: 313px;
  position: relative;
}
.awards__block:nth-of-type(2) {
  margin-left: auto;
  width: 600px;
  justify-content: flex-end;
  height: 313px;
  position: relative;
}
.awards-card:first-of-type {
  background: linear-gradient(90deg, #1da1f2, #009ffc);
  left: 0;
  top: 0;
}
.awards-card:nth-of-type(2) {
  background: #f7df1e;
  right: 0;
  top: 0;
}
.awards-card:nth-of-type(3) {
  background-color: #1958e3;
  left: 0;
  bottom: 0;
  margin-right: 0;
}
.awards-card:nth-of-type(4) {
  background-color: #5f01d1;
  right: 0;
  bottom: 0;
}
.awards-card:first-of-type .awards-card__logo {
  width: 106px;
}

.awards-card__logo {
  width: 151px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: 0.5s;
  transform: translate(-50%, -50%);
}

element.style {
}
.awards-card__text {
  margin: 0;

  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  width: 251px;
  top: 73px;
  position: absolute;
  letter-spacing: 0.05em;
  opacity: 0;
  transition: 0.4s;
  overflow: hidden;
}
.awards-card:nth-of-type(2) .awards-card__text {
  color: #0d1227;
}
.clients {
  padding-top: 83px;
  padding-bottom: 84px;
  background-color: #f5f8ff;
}
.clients__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.clients__title {
  font-weight: 700;
  font-size: 48px;
  line-height: 53px;
  color: #0f0f0f;
  text-align: center;
  margin: 0 0 77px;
}
.clients__block {
  margin-bottom: 76px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 8px;
  grid-auto-rows: 1fr;
}
.clients__source {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.clients__gray {
  position: absolute;
  transition: 0.4s;
  opacity: 1;
  z-index: 5;
  max-width: 190px;
}
.clients__image {
  transition: 0.4s;
  opacity: 0;
}
.clients__source:hover .clients__gray {
  opacity: 1;
}
.clients__source:hover .clients__image {
  opacity: 1;
}
.clients__link {
  margin: 0;

  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: #1958e3;
  padding: 16px 75px;
  position: relative;
  text-decoration: none;
  transition: 0.4s;
  cursor: pointer;
}
.clients__link:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  outline: 1px solid #1958e3;
  box-shadow: 0 2.31722px 1.85378px rgb(0 49 255 / 2%),
    0 5.38772px 4.31018px rgb(0 49 255 / 3%),
    0 9.67509px 7.74008px rgb(0 49 255 / 3%),
    0 16.0571px 12.8457px rgb(0 49 255 / 4%),
    0 24px 21.1628px rgb(0 49 255 / 4%);
  transition: 0.4s;
  opacity: 0;
}
.clients__link:hover:after {
  opacity: 1;
}
.reviews {
  padding-top: 93px;
  padding-bottom: 88px;
}
.reviews {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 64px;
}
.reviews__wrapper {
  display: flex;
  position: relative;
}
.reviews__wrapper {
  flex-direction: column;
  max-width: 75%;
}
.reviews__title {
  margin: 0;

  font-weight: 700;
  font-size: 64px;
  line-height: 70px;
  color: #0f0f0f;
  letter-spacing: -0.015em;
  max-width: 406px;
  position: absolute;
  top: 0;
  left: 0;
}
.reviews__title {
  margin-bottom: 64px;
  max-width: 100%;
  font-size: 48px;
  line-height: 53px;
  position: inherit;
}
.reviews__block {
  margin-left: 0;
  max-width: 75%;
}
.reviews-step {
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  color: #1958e3;
  margin: 0 0 96px;
}
.reviews-step {
  margin-bottom: 24px;
  font-size: 24px;
  line-height: 29px;
}
.reviews-step__span {
  color: #a9b1cc;
}
.reviews-view {
  animation-name: reviewsFade;
  animation-iteration-count: 1;
  animation-duration: 0.8s;
}
.reviews-view__title {
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: #0f0f0f;
  margin: 0 0 8px;
}
.reviews-view__subtitle {
  margin-bottom: 32px;
}
.reviews-view__subtitle,
.reviews-view__text {
  font-weight: 500;
  color: #4b4d51;
}
.reviews-view__subtitle,
.reviews-view__text {
  font-weight: 500;
  color: #4b4d51;
}

.reviews-view__text {
  margin-bottom: 40px;
  max-width: 100%;
}
.reviews-view__text {
  font-size: 18px;
  line-height: 23px;
  margin: 0 0 145px;
  letter-spacing: 0.01em;
  max-width: 439px;
  white-space: pre-wrap;
}
.reviews-control {
  display: flex;
  align-items: center;
}
.reviews-control__svg:first-of-type {
  margin-right: 16px;
}
.whyus {
  padding-top: 55px;
  background-color: #1958e3;
}
.whyus_border {
  border-top: 1px solid hsla(0, 0%, 100%, 0.3);
}
.whyus__title {
  font-family: Gilroy;
  font-weight: 700;
  font-size: 64px;
  line-height: 70px;
  color: #fff;
  letter-spacing: -0.015em;
  margin: 0 0 55px;
}
.whyus-card {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.whyus-card__item {
  padding: 48px 43px 32px 27px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.whyus-card__item:first-child,
.whyus-card__item:nth-child(3) {
  border-left: 1px solid hsla(0, 0%, 100%, 0.3);
  border-right: 1px solid hsla(0, 0%, 100%, 0.3);
}
.whyus-card_flex {
  display: flex;
  flex-direction: column;
  transition: 0.4s;
}
.whyus-card__title {
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  margin: 0 0 24px;
}
.whyus-card__text,
.whyus-card__title {
  color: #fff;
}
.whyus-card__span {
  margin-top: 22px;
  display: inline-flex;
}
.whyus-card__index {
  font-weight: 700;
  font-size: 64px;
  line-height: 70px;
  color: hsla(0, 0%, 100%, 0.1);
  margin: 88px 0 0;
  letter-spacing: -0.015em;
  transition: 0.4s;
}
.portfolio {
  padding-top: 88px;
  padding-bottom: 80px;
  display: none;
}
.portfolio__subtitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #1958e3;
  margin: 0 0 12px;
}
.portfolio__title {
  font-weight: 700;
  font-size: 64px;
  line-height: 70px;
  color: #0f0f0f;
  margin: 0 0 24px;
  letter-spacing: -0.015em;
}
.portfolio__text {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #4b4d51;
  margin: 0 0 40px;
  letter-spacing: 0.05em;
  max-width: 513px;
}
.portfolio__block {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}
.portfolio-item {
  padding: 32px 48px;
  height: 336px;
  text-decoration: none;
  background-repeat: no-repeat;
  transition: 0.4s;
}

.portfolio-item:first-of-type {
  grid-area: 1/4/2/6;  background-color: #6734df;
  background-position: 100%;
  background-position-y: 75px;
}

.portfolio-item:nth-of-type(2) {
  grid-area: 1/1/2/4;
  background-color: #f2c94c;
  background-position: 100% 100%;
}
.portfolio-item:nth-of-type(3) {
  grid-area: 2/1/3/3;
    background-color: #f2c94c;
  background-position: bottom;
}
.portfolio-item:nth-of-type(4) {
  grid-area: 3/4/4/6;background-position: bottom;
}
.portfolio-item:nth-of-type(5) {
  grid-area: 3/1/4/4;background-color: #001a41;
  background-position: 100% 100%;
}
.portfolio-item:nth-of-type(6) {
  grid-area: 2/3/3/6; background-position: bottom;
  background-size: 532px, auto;
}
.portfolio-item__title {
  font-family: Gilroy, sans-serif;
  font-weight: 700;
  font-size: 36px;
  line-height: 45px;
  color: #fff;
  margin: 0 0 8px;
}
.portfolio-item__tags {
  margin: 0;
  font-family: Gilroy, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #fff;
}
.news {
  padding-top: 64px;
  padding-bottom: 34px;
  background-color: #f5f8ff;
  border-bottom: 8px solid #1958e3;
  overflow: hidden;
}
.news__link {
  margin: 0;
  font-weight: 600;
  font-size: 36px;
  line-height: 43px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  position: relative;
  transition: 0.4s;
}
.news__link,
.news__title {
  color: #0f0f0f;
}
.news__link_span {
  position: inherit;
  z-index: 1;
}
.news__link:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -41px;
  width: 100%;
  height: 128px;
  background-color: #1958e3;
  transition: 0.4s;
  transform: translateY(100%);
}
.footer-mail {
  background-color: #13172c;
  transition: 0.4s;
  overflow: hidden;
  max-height: calc(100vh - 77px);
  padding: 0px;
  overflow-y: auto;
}
.footer-mail__wrapper {
  display: grid;
  grid-template-columns: 513px 1fr;
  grid-column-gap: 131px;
  padding-bottom: 77px;
  padding-top: 77px;
}
.footer-mail__subtitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #ffd180;
  margin: 0 0 48px;
}
.footer-mail__title {
  font-weight: 700;
  font-size: 64px;
  line-height: 70px;
  margin: 0 0 65px;
  letter-spacing: -0.015em;
}
.footer-mail__text,
.footer-mail__title {
  color: #fff;
}
.footer-mail__text,
.footer-mail__title {
  color: #fff;
}
.footer-mail__text {
  margin: 0;
  font-weight: 500;
  font-size: 28px;
  line-height: 39px;
}
.footer-mail__link {
  text-decoration: none;
  font-weight: 700;
  color: #ffd180;
  position: relative;
}
.footer-mail__link:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: #ffd180;
  transition: 0.4s;
}
.footer-mail__block:nth-of-type(2) {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.footer-mail .footer-form__block:first-of-type {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 23px;
  grid-row-gap: 25px;
  margin-bottom: 54px;
}
.footer-mail .footer-form__input,
.footer-mail .footer-form__textarea {
  margin: 0;
  padding: 17px;

  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.01em;
  color: #a9b1cc;
  box-sizing: border-box;
  border: 1px solid hsla(0, 0%, 100%, 0.05);
  border-bottom-color: hsla(0, 0%, 100%, 0.3);
  background-color: transparent;
}
.footer-mail .footer-form__textarea {
  margin-bottom: 24px;
  width: 100%;
  resize: vertical;
  min-height: 80px;
  max-height: 210px;
}
.footer-mail .footer-form__block:nth-of-type(2) {
  margin-bottom: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer-mail .footer-form-checkbox,
.footer-mail .footer-form-file {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.footer-mail .footer-form-checkbox__input,
.footer-mail .footer-form-file__input {
  display: none;
}
.footer-mail .footer-form-checkbox__span,
.footer-mail .footer-form-file__span {
  margin-right: 18px;
}
.footer-mail .footer-form-checkbox__text,
.footer-mail .footer-form-file__text {
  margin: 0;

  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #fff;
  max-height: 22.4px;
}
.footer-mail .footer-form-checkbox,
.footer-mail .footer-form-file {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.footer-mail .footer-form-checkbox__input,
.footer-mail .footer-form-file__input {
  display: none;
}
.footer-mail .footer-form-file__span {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 42px;
  min-height: 42px;
  border-radius: 50%;
  background-color: #fff;
}
.footer-mail .footer-form-checkbox__input:checked+.footer-form-checkbox__span:after {
  content: "";
  display: flex;
  width: 6px;
  height: 6px;
  background-color: #fff;
}
.footer-mail .footer-form-checkbox__span,
.footer-mail .footer-form-file__span {
  margin-right: 18px;
}
.footer-mail .footer-form-checkbox__text,
.footer-mail .footer-form-file__text {
  margin: 0;

  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #fff;
  max-height: 22.4px;
}

.footer-mail .footer-form__block:nth-of-type(3) {
  display: grid;
  grid-template-columns: 189px 1fr;
  grid-column-gap: 26px;
}

.footer-mail .footer-form__link {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #fff;
  background-color: #1958e3;
  padding: 17px;
  text-decoration: none;
  text-align: center;
  transition: 0.4s;
}
.footer-mail .footer-form__submit {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #0f0f0f;
  background-color: #fff;
  padding: 17px;
  border: none;
  text-align: center;
  cursor: pointer;
  transition: 0.4s;
}
.footer-mail .footer-form-file__text {
  max-width: 240px;
  overflow: hidden;
}
.footer-mail .footer-form-checkbox__span {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  box-sizing: border-box;
  border: 2px solid #fff;
}
.footer-main {
  padding-top: 80px;
  padding-bottom: 24px;
}
.footer-main__wrapper {
  display: flex;
}
.footer-main__wrapper:first-child {
  margin-bottom: 64px;
}
.footer-main__title {
  margin: 0;

  font-weight: 700;
  font-size: 64px;
  line-height: 70px;
  color: #0f0f0f;
  letter-spacing: -0.015em;
  max-width: 590px;
}

.footer-main__title_blue {
  color: #1958e3;
}
.footer-main-nav {
  display: grid;
  grid-template-columns: 100px 154px 233px;
  grid-column-gap: 89px;
  grid-row-gap: 59px;
}
.footer-main-nav__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  width: 200px;
}
.footer-main-nav__item {
  margin-bottom: 12px;
}
.footer-main-nav__item:first-child {
  line-height: 21px;
  color: #1958e3;
  margin: 0 0 16px;
}
.footer-main-nav__item:first-child,
.footer-main-nav__link {
  font-weight: 500;
  font-size: 16px;
}
.footer-main-nav__item:first-child,
.footer-main-nav__link {
  font-weight: 500;
  font-size: 16px;
}
.footer-main-nav__link {
  margin: 0;
  line-height: 19px;
  color: #4b4d51;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  text-decoration: none;
  transition: 0.4s;
  position: relative;
}
.footer-main-nav__link:before {
  content: "";
  width: 7.3px;
  height: 8px;
  position: absolute;
  display: block;
  left: -15px;
  transform: translate(100%);
  opacity: 0;
  transition: 0.4s;
  background-repeat: no-repeat;
  background-size: contain;
}
.footer-main-nav-media {
  display: flex;
}
.footer-main-nav-media__link {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 14px;
  min-width: 42px;
  min-height: 42px;
  border-radius: 50%;
  background-color: rgba(15, 15, 15, 0.05);
  transition: 0.4s;
}
.footer-main-nav-media__icon {
  transition: 0.4s;
}
.footer-main__wrapper {
  display: flex;
}
.footer-main-info {
  display: flex;
  flex-direction: column;
}
.footer-main-info__text {
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #0f0f0f;
  margin: 0 0 32px;
  max-width: 270px;
}
.footer-main-info__block {
  margin-bottom: 34px;
  display: grid;
  grid-auto-columns: 1fr;
}
.footer-main-info__link_blue {
  font-size: 18px;
  line-height: 25px;
  color: rgba(15, 15, 15, 0.72);
  letter-spacing: 0.01em;
  white-space: pre-wrap;
}
.footer-main-info__link_blue,
.footer-main__data {
  margin: 0;

  font-weight: 500;
  text-decoration: none;
}
.footer-main-info__link {
  margin: 0;

  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #1958e3;
  text-decoration: none;
}
.footer-main-info__link:first-of-type {
  margin-bottom: 10px;
}
.footer-main-info__link {
  margin: 0;

  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #1958e3;
  text-decoration: none;
}
.footer-main__world_flex {
  display: flex;
  margin-left: 70px;
}
.footer-main__world {
  width: 97%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.footer-main__wrapper:nth-child(3) {
  display: grid;
  grid-template-columns: 1fr 92px 103px;
  grid-column-gap: 36px;
}
.footer-main-info__link_blue,
.footer-main__data {
  margin: 0;

  font-weight: 500;
  text-decoration: none;
}
.footer-main-info__link_blue,
.footer-main__data {
  margin: 0;

  font-weight: 500;
  text-decoration: none;
}
.footer-main__data {
  font-size: 16px;
  line-height: 21px;
  color: #a9b1cc;
  transition: 0.4s;
}

.whywe {
  padding-top: 137px;
}
.whywe__block {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto auto;
  grid-column-gap: 20px;
  margin-bottom: 48px;
}
.whywe__title {
  margin: 0;

  font-weight: 700;
  font-size: 64px;
  line-height: 70px;
  color: #0f0f0f;
  letter-spacing: -0.015em;
}
.whywe__arrow {
  cursor: pointer;
}
.whywe .slider {
  height: 360px;
  position: relative;
  overflow: hidden;
}
.whywe .slider__wrapper {
  display: flex;
  position: absolute;
  transition: 0.8s;
}
.whywe .slider-item {
  width: 428px;
  padding: 32px 45px 47px 40px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  background-color: #f5f8ff;
  margin-right: 24px;
}
.whywe .slider-item__title {
  font-weight: 700;
  font-size: 64px;
  line-height: 70px;
  color: #0f0f0f;
  margin: 0 0 64px;
  letter-spacing: -0.015em;
  -webkit-text-stroke: 1px #1958e3;
  -webkit-text-fill-color: transparent;
}
.whywe .slider-item__text {
  margin: 0;

  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #4b4d51;
  max-width: 428px;
}
.ourhistory {
  padding-top: 80px;
}
.ourhistory__title {
  font-weight: 700;
  font-size: 64px;
  line-height: 70px;
  color: #0f0f0f;
  margin: 0 0 56px;
  letter-spacing: -0.015em;
}
.ourhistory-item {
  display: grid;
  align-items: center;
}
.ourhistory-item:nth-of-type(2) {
  grid-column-gap: 132px;
  grid-template-columns: 513px 405px;
  justify-content: center;
}

.ourhistory-item:first-of-type,
.ourhistory-item:nth-of-type(3) {
  grid-column-gap: 64px;
  grid-template-columns: 405px 513px;
}

.ourhistory-item__image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.ourhistory-item__text {
  margin: 0;

  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #0f0f0f;
  letter-spacing: 0.01em;
  max-width: 513px;
  position: relative;
}
.ourhistory-item__text:before {
  content: "";
  display: block;
  position: absolute;
  top: -26px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #1958e3;
}
.ourmission {
  padding-top: 80px;
  padding-bottom: 80px;
}
.ourmission__title {
  font-weight: 700;
  font-size: 64px;
  line-height: 70px;
  color: #0f0f0f;
  letter-spacing: -0.015em;
  margin: 0 0 88px;
}
.ourmission__block {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(405px, 1fr));
  grid-column-gap: 24px;
  grid-row-gap: 80px;
}
.ourmission-item__icon {
  margin-bottom: 24px;
  transition: 0.4s;
}
.ourmission-item__text {
  margin: 0;

  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #0f0f0f;
  letter-spacing: 0.01em;
}
.teams {
  padding-bottom: 88px;
}
.teams__title {
  margin: 0;

  font-weight: 700;
  font-size: 64px;
  line-height: 70px;
  color: #0f0f0f;
  letter-spacing: -0.015em;
}
.teams-card {
  margin-top: 57px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}
.teams-card__item {
  padding: 48px;
  height: 241px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
/* .teams-card__item:first-of-type {
  background-image: url(../images/team-1.png);
}
.teams-card__item:nth-of-type(2) {
  background-image: url(../images/team-2.png);
}
.teams-card__item:nth-of-type(3) {
  background-image: url(../images/team-3.png);
}
.teams-card__item:nth-of-type(4) {
  background-image: url(../images/team-4.png);
}
.teams-card__item:nth-of-type(5) {
  background-image: url(../images/team-5.png);
}
.teams-card__item:nth-of-type(6) {
  background-image: url(../images/team-6.png);
}
.teams-card__item:nth-of-type(7) {
  background-image: url(../images/team-7.png);
}
.teams-card__item:nth-of-type(8) {
  background-image: url(../images/team-8.png);
} */
.teams-card__title {
  margin: 0;
  font-size: 36px;
  line-height: 43px;
  text-align: center;
  width: calc(100% - 96px);
}
.teams-card__title,
.teams-card__title_sm {
  font-weight: 700;
  color: #fff;
}
.teams-card__content {
  padding: 48px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(100%);
  transition: 0.4s;
}
.teams-card__title,
.teams-card__title_sm {
  font-weight: 700;
  color: #fff;
}
.teams-card__title_sm {
  font-size: 28px;
  line-height: 34px;
  margin: 0 0 16px;
}
.teams-card__text {
  margin: 0;

  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #fff;
  letter-spacing: 0.01em;
  z-index: 10;
  position: inherit;
  max-width: 61%;
}
.teams-card__item:first-of-type .teams-card__content {
  background-color: #1958e3;
}
.teams-card__item:nth-of-type(2) .teams-card__content {
  background-color: #f7b801;
}
.teams-card__item:nth-of-type(3) .teams-card__content {
  background-color: #50c9ce;
}
.teams-card__item:nth-of-type(4) .teams-card__content {
  background-color: #f79ad3;
}
.teams-card__item:nth-of-type(5) .teams-card__content {
  background-color: #5887ff;
}
.teams-card__item:nth-of-type(6) .teams-card__content {
  background-color: #e63462;
}
.teams-card__item:nth-of-type(7) .teams-card__content {
  background-color: #f39a47;
}
.teams-card__item:nth-of-type(8) .teams-card__content {
  background-color: #97d38d;
}
.teams-card__item:hover .teams-card__content {
  transform: none;
}
.career {
  padding-top: 137px;
  padding-bottom: 88px;
}
.career__title {
  font-size: 64px;
  line-height: 70px;
  color: #0f0f0f;
  margin: 0 0 47px;
  letter-spacing: -0.015em;
  max-width: 584px;
}
.career__subtitle,
.career__title {
  font-weight: 700;
}
.career__subtitle {
  font-size: 28px;
  line-height: 34px;
  color: #1958e3;
  margin: 0 0 57px;
}
.career-info {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 81px;
}
.career-info__text {
  margin: 0;

  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #4b4d51;
  display: flex;
  letter-spacing: 0.05em;
  max-width: 513px;
}
.career-info__text:first-of-type {
  margin-right: 24px;
}
.career-job {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(405px, 1fr));
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}
.career-job__item {
  cursor: pointer;
}
.career-job__block:first-child {
  padding: 31px 26px 59px 40px;
  display: flex;
  flex-direction: column;
  border: 1px solid #a9b1cc;
  border-bottom: none;
}
.career-job__title {
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: #0f0f0f;
  margin: 0 0 16px;
  transition: 0.4s;
}
.career-job__address {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #4b4d51;
  letter-spacing: 0.01em;
}
.career-job__address,
.career-job__apply {
  margin: 0;

  transition: 0.4s;
}
.career-job__block:nth-child(2) {
  padding: 17px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1958e3;
}
.career-job__address,
.career-job__apply {
  margin: 0;

  transition: 0.4s;
}
.career-job__apply {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #fff;
}
.whywork {
  padding-top: 64px;
  padding-bottom: 88px;
  background-color: #f5f8ff;
}
.whywork__title {
  font-weight: 700;
  font-size: 64px;
  line-height: 70px;
  color: #0f0f0f;
  margin: 0 0 64px;
  letter-spacing: -0.015em;
}
.whywork__wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(556px, 1fr));
  grid-column-gap: 86px;
  grid-row-gap: 88px;
}
.whywork-item {
  display: flex;
  position: relative;
}
.whywork-item__title {
  font-weight: 700;
  font-size: 64px;
  line-height: 70px;
  color: #0f0f0f;
  margin: 0 88px 0 0;
  letter-spacing: -0.015em;
  -webkit-text-stroke: 1px #1958e3;
  -webkit-text-fill-color: transparent;
  transition: 0.4s;
  position: absolute;
  top: 0;
  left: 0;
}
.whywork-item__block {
  display: flex;
  flex-direction: column;
  margin-left: 155px;
}
.whywork-item__text {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #4b4d51;
  margin: 0 0 32px;
}
.whywork-item__slogan {
  margin: 0;

  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #0f0f0f;
}

.survey {
  padding-top: 137px;
  padding-bottom: 88px;
}
.survey__title {
  font-weight: 700;
  font-size: 64px;
  line-height: 70px;
  color: #0f0f0f;
  letter-spacing: -0.015em;
  max-width: 900px;
}
.survey__text,
.survey__title {
  margin: 0 0 44px;
}
.survey__text {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #4b4d51;
  letter-spacing: 0.05em;
  max-width: 835px;
}
.survey__subtitle {
  margin: 0;

  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: #1958e3;
}
.quiz {
  padding-top: 64px;
  padding-bottom: 64px;
  background-color: #f5f8ff;
}
.quiz__block:first-of-type {
  margin-bottom: 31px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto auto 95px;
}
.quiz__block:nth-of-type(2) {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}
.quiz-btn,
.quiz-item__title {
  font-weight: 700;
  transition: 0.4s;
}
.quiz-btn {
  font-size: 28px;
  line-height: 34px;
  color: #fff;
  background-color: #1958e3;
  margin-top: 48px;
  padding: 27px 24px;
  display: flex;
  align-items: center;
  width: calc(50% - 12px);
  border: none;
  cursor: pointer;
}
.quiz-btn__svg {
  margin-left: 28px;
  transition: 0.4s;
}
.quiz-item__checkbox {
  display: none;
}
.quiz-item__label {
  height: 104px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #1958e3;
  transition: 0.4s;
  cursor: pointer;
}
.quiz-item__icon {
  margin-bottom: 16px;
}
.quiz-item__title {
  margin: 0;
  font-size: 24px;
  line-height: 29px;
  color: #0f0f0f;
}
.quiz-btn,
.quiz-item__title {
  font-weight: 700;
  transition: 0.4s;
}
.quiz-item__checkbox:checked + .quiz-item__label {
  background-color: #1958e3;
}
.quiz-item__checkbox:checked + .quiz-item__label > .quiz-item__icon {
  filter: brightness(26);
}
.quiz-item__checkbox:checked + .quiz-item__label > .quiz-item__title {
  color: #fff;
}
.quiz__step {
  margin: 0;

  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #1958e3;
  text-align: right;
}
.quiz__step,
.quiz__step--span {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.quiz__step,
.quiz__step--span {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.quiz__step--span {
  color: #a9b1cc;
}
.quiz__arrow {
  cursor: pointer;
  transition: 0.4s;
}
.quiz__arrow:first-of-type {
  margin-right: 8px;
  opacity: 1;
}
.quiz__arrow:nth-of-type(2) {
  margin-right: 24px;
  opacity: 0.5;
}
.quiz__arrow.hide {
  opacity: 0.5;
}
.quiz__arrow.active {
  opacity: 1;
}
.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  background-color: rgba(15, 15, 15, 0.3);
}
.modal,
.modal__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modal,
.modal__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modal__wrapper {
  padding: 76px 108px 80px 107px;
  position: relative;
  background-color: #fff;
  animation-name: modalAnim;
  animation-duration: 0.8s;
  animation-iteration-count: 1;
}
.modal__close {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
}
.modal__title {
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: #0f0f0f;
  margin: 0 0 48px;
  text-align: center;
}
.modal-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 23px;
  grid-row-gap: 26px;
}
.modal-form__mail,
.modal-form__name,
.modal-form__phone,
.modal-form__text {
  margin: 0;

  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #0f0f0f;
  letter-spacing: 0.01em;
  padding: 16px;
  min-width: 300px;
  border: 1px solid rgba(15, 15, 15, 0.1);
  box-sizing: border-box;
  transition: 0.4s;
}
.modal-form__name {
  grid-area: 1/1/2/2;
}
.modal-form__mail {
  grid-area: 1/2/2/3;
}
.modal-form__phone {
  grid-area: 2/1/3/3;
}
.modal-form__text {
  grid-area: 3/1/4/3;
  margin-bottom: 14px;
  resize: none;
}
.modal-form__submit {
  grid-area: 4/1/5/2;

  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #fff;
  background-color: #1958e3;
  padding: 18px;
  border: none;
  cursor: pointer;
}
.modal-form-file {
  grid-area: 4/2/5/3;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}
.modal-form-file__input {
  display: none;
}
.modal-form-file__span {
  margin-right: 16px;
  display: flex;
  align-items: center;
}
.modal-form-file__text {
  margin: 0;

  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #0f0f0f;
  letter-spacing: 0.01em;
  max-height: 22px;
  overflow: hidden;
}
.service {
  padding-top: 137px;
  padding-bottom: 128px;
}
.service__title {
  font-weight: 700;
  font-size: 64px;
  line-height: 70px;
  margin: 0 0 32px;
  letter-spacing: -0.015em;
}
.service__text,
.service__title {
  color: #0f0f0f;
}
.service__text {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.01em;
  max-width: 835px;
  margin: 0 0 55px;
  white-space: pre-wrap;
}
.service-tech {
  display: grid;
  grid-row-gap: 48px;
  max-width: 1051px;
  margin-bottom: 88px;
}
.service-tech__block {
  display: grid;
  grid-row-gap: 24px;
}
.service-tech__title {
  margin: 0;

  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: #0f0f0f;
}
.service-techList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(191px, 191px));
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}
.service-techList__item {
  width: 191px;
  height: 184px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f5f8ff;
}
.service-techList__icon {
  height: 80px;
  margin-top: 10px;
  -o-object-fit: contain;
  object-fit: contain;
  transition: 0.4s;
}
.service-techList__name {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  margin: 10px 0 0;
  text-align: center;
}
.service-techList__name,
.service__subtitle {
  color: #0f0f0f;
}
.service__subtitle {
  margin: 0;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
}
.service-help {
  margin: 35px 0 95px;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(3, 217px);
  grid-column-gap: 105px;
  grid-row-gap: 14px;
  list-style-type: none;
}
.service-help__item {
  margin: 0;

  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #0f0f0f;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  width: -webkit-max-content;
  width: max-content;
}
.service-help__item:before {
  content: "";
  margin-right: 24px;
  display: flex;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #1958e3;
}
.service-link {
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: #fff;
  background-color: #1958e3;
  padding: 27px 24px;
  display: flex;
  align-items: center;
  max-width: 572px;
  text-decoration: none;
}
.service-link,
.service-link__span {
  transition: 0.4s;
}
.service-link,
.service-link__span {
  transition: 0.4s;
}
.service-link__arrow {
  margin-left: 32px;
  transition: 0.4s;
}
.brandNameBox {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  background-color: #f5f8ff;
}
.brandNameBox .brandBox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 99vh;
}
.brandName {
  color: black;
  font-size: 30px;
  text-align: center;
  letter-spacing: 0.5;
}
.blog {
  padding-top: 137px;
}
.blog__title {
  font-family: Gilroy;
  font-weight: 700;
  font-size: 64px;
  line-height: 70px;
  color: #0f0f0f;
  margin: 0 0 64px;
  text-align: center;
  letter-spacing: -.015em;
}
.blog-head {
  margin-bottom: 48px;
  height: 460px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  text-decoration: none;
  position: relative;
  transition: .4s;
}
.blog-head__title {
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  margin: 0 0 36px;
  max-width: 85%;
}
.blog-head__text, .blog-head__title {
  font-family: Gilroy;
  color: #fff;
  text-align: center;
  z-index: 1;
}
.blog-head:hover .blog-head__link {
  color: #fff;
  border-color: #fff;
  background-color: transparent;
}
.blog-head__text {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: .05em;
  margin: 0 0 44px;
  max-width: 771px;
}
.blog-head__link {
  font-size: 16px;
  line-height: 21px;
  color: #0f0f0f;
  background-color: #fff;
  padding: 16px 75px;
  border: 1px solid transparent;
  text-decoration: none;
  transition: .4s;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}

.blog-head__link, .blog__subtitle {
  font-family: Gilroy;
  font-weight: 700;
}
.blog__subtitle {
  font-size: 36px;
  line-height: 43px;
  color: #1958e3;
  margin: 0 0 32px;
  text-align: center;
}
.blog__block {
  margin-bottom: 106px;
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(405px,1fr));
  grid-column-gap: 24px;
  grid-row-gap: 48px;
}
.blog-item {
  cursor: pointer;
  text-decoration: none;
}
.blog-item__block:first-of-type {
  height: 228px;
  position: relative;
  overflow: hidden;
}
.blog-item__view {
  margin: 0;
  font-family: Gilroy;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #fff;
  padding: 0 32px;
  width: calc(100% - 64px);
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  letter-spacing: .01em;
  transition: .4s;
  background-color: rgba(25,88,227,.5);
  transform: translateY(100%);
  mix-blend-mode: normal;
}
.blog-item:hover .blog-item__view {
  transform: none;
}
.blog-item__image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.blog-item__block:nth-of-type(2) {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
}
.blog-item__title {
  font-family: Gilroy;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #0f0f0f;
  margin: 8px 0 16px;
}
.blog-author {
  display: flex;
  align-items: center;
}
.blog-author__image {
  margin-right: 16px;
  width: 36px;
  height: 36px;
}
.blog-author__source {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
}
.blog-author__block {
  display: flex;
  flex-direction: column;
}
.blog-item__tag {
  font-family: Gilroy;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #0f0f0f;
  margin: 0 8px 8px 0;
  padding: 3px 9px;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid #0f0f0f;
}
.blog-item__tag_business {
  border-color: #bc0942;
  color: #bc0942;
}
.blog-author__title {
  font-size: 14px;
  line-height: 17px;
  color: #4b4d51;
  margin: 0 0 4px;
}
.blog-author__text, .blog-author__title {
  font-family: Gilroy;
  font-weight: 500;
}
.blog-author__text, .blog-author__title {
  font-family: Gilroy;
  font-weight: 500;
}
.blog-author__text {
  margin: 0;
  font-size: 12px;
  line-height: 14px;
  color: #a9b1cc;
}
.article-head {
  height: 370px;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.article-head:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: hsla(0,0%,100%,.05);
  -webkit-backdrop-filter: blur(27px);
  backdrop-filter: blur(27px);
}
.article-head__title {
  font-weight: 700;
  font-size: 36px;
  line-height: 47px;
  color: #fff;
  margin: 0 0 16px;
}

.article-head__subtitle, .article-head__title {
  font-family: Gilroy;
  max-width: 85%;
  text-align: center;
  z-index: 1;
}
.article-head__subtitle, .article-head__title {
  font-family: Gilroy;
  max-width: 85%;
  text-align: center;
  z-index: 1;
}
.article-head__subtitle {
  margin: 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: hsla(0,0%,100%,.72);
}
.article-info {
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(169,177,204,.3);
}
.article-info__wrapper {
  margin: 0 auto;
  max-width: 620px;
  display: flex;
  align-items: center;
}
.article-info__block:first-of-type {
  padding-right: 16px;
  min-width: 190px;
  display: grid;
  align-items: center;
  grid-template-columns: 44px auto;
  grid-template-rows: repeat(2,1fr);
  grid-column-gap: 16px;
  grid-row-gap: 4px;
  position: relative;
}
.article-info__block:nth-of-type(2) {
  margin-left: 24px;
  display: flex;
  flex-wrap: wrap;
}
.article-info__block:nth-of-type(3) {
  margin-left: auto;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-template-rows: repeat(2,1fr);
  grid-column-gap: 22px;
  grid-row-gap: 7px;
}
.article-info__photo {
  grid-area: 1/1/3/2;
  width: 44px;
  height: 44px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
}
.article-info__name {
  font-size: 16px;
  line-height: 19px;
  color: #4b4d51;
  grid-area: 1/2/2/3;
}
.article-info__name, .article-info__position {
  margin: 0;
  font-family: Gilroy;
  font-weight: 500;
}
.article-info__photo {
  grid-area: 1/1/3/2;
  width: 44px;
  height: 44px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
}
.article-info__block:first-of-type:after {
  content: "";
  width: 1px;
  height: 51px;
  display: flex;
  position: absolute;
  right: 0;
  background-color: #a9b1cc;
}
.article-info__tag {
  font-family: Gilroy;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #0f0f0f;
  padding: 3px 8px;
  margin: 0 8px 4px 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid #0f0f0f;
}
.article-info__tag_development {
  color: #1585d6;
  border-color: #1585d6;
}
.article-info__tag:last-of-type {
  margin: 0;
}
.article-info__share {
  margin: 0;
  font-family: Gilroy;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #a9b1cc;
  grid-area: 1/1/2/4;
}
.article-info__link {
  cursor: pointer;
}

.article-info__link:first-of-type {
  grid-area: 2/1/3/2;
}
.article-info__link:nth-of-type(2) {
  grid-area: 2/2/3/3;
}
.article-info__link:nth-of-type(3) {
  grid-area: 2/3/3/4;
}
.article-item {
  padding-top: 32px;
  padding-bottom: 64px;
  margin: 0 auto;
  max-width: 620px;
  min-height: 150px;
  position: relative;
}
.article-item__letter {
  margin: 0;
  font-family: Gilroy;
  font-weight: 500;
  font-size: 144px;
  line-height: 158px;
  color: #f5f8ff;
  position: absolute;
  top: 0;
  left: -45px;
  z-index: -1;
}
.article-item li, .article-item p {
  font-family: Gilroy;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #1e1e1e;
  letter-spacing: .05em;
}
.article-item p {
  margin: 0 0 15px;
}
.article-item h1, .article-item h2, .article-item h3, .article-item h4, .article-item h5, .article-item h6 {
  font-family: Gilroy;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: #0f0f0f;
  margin: 0 0 15px;
}
.article-item p {
  margin: 0 0 15px;
}
.case-head {
  height: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.case.pyoor .case-head {
  background-image: url(https://d33wubrfki0l68.cloudfront.net/ab1f5034f4618b0698484c76bb4e4ad279995f16/2fad7/images/cases/pyoor/background.png);
  background-position: 50%;
}
.case-head__title {
  font-weight: 700;
  font-size: 64px;
  line-height: 70px;
  letter-spacing: -.015em;
  margin: 24px 0;
}
.case-head__tech, .case-head__title {
  font-family: Gilroy,sans-serif;
  color: #fff;
  text-align: center;
}
.case-head__tech, .case-head__title {
  font-family: Gilroy,sans-serif;
  color: #fff;
  text-align: center;
}
.case-head__tech {
  margin: 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}
.case-product {
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(488px,1fr));
  grid-column-gap: 88px;
  justify-content: space-between;
  margin-top: 64px;
}
.case-product__title {
  font-family: Gilroy,sans-serif;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  color: #0f0f0f;
  margin: 0 0 36px;
}
.case-product__text {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #4b4d51;
  margin: 0 0 44px;
  white-space: pre-wrap;
}
.case-product__link, .case-product__text {
  font-family: Gilroy,sans-serif;
  max-width: 513px;
}
.case-product__link, .case-product__text {
  font-family: Gilroy,sans-serif;
  max-width: 513px;
}
.case-product__link {
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: #fff;
  background-color: #1958e3;
  margin-bottom: 16px;
  padding: 23px 48px 23px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
}
.case.pyoor .case-product__link {
  background: linear-gradient(
90deg,#1ebb7f,#2deaa1);
}
.case-product__link_span {
  transition: .4s;
}
.case-product__source {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.case-techList {
  padding-top: 64px;
  padding-bottom: 128px;
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(488px,1fr));
  grid-column-gap: 88px;
  grid-row-gap: 72px;
}
.case-techList__title {
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  color: #0f0f0f;
}
.case-techList__text, .case-techList__title {
  font-family: Gilroy,sans-serif;
  margin: 0 0 24px;
}
.case-techList__text {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #4b4d51;
  letter-spacing: .05em;
  max-width: 515px;
}
.case-techList__tools {
  font-family: Gilroy,sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #0f0f0f;
  margin: 0 0 16px;
}
.case-techListTools {
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(160px,160px));
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}
.case-techListTools__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 160px;
  background-color: #f5f8ff;
}
.case-techListTools__icon {
  height: 64px;
  margin-top: 15px;
  -o-object-fit: cover;
  object-fit: cover;
}
.case-techListTools__title {
  font-family: Gilroy,sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #4b4d51;
  margin: 10px 0 0;
}
.case-gallery {
  padding-top: 96px;
  padding-bottom: 164px;
  background-color: #13172c;
}
.case-gallery__wrapper {
  display: grid;
  grid-row-gap: 96px;
}
.case.pyoor .case-gallery__wrapper {
  grid-template-columns: 1fr 1fr;
}
.case-gallery__title {
  font-family: Gilroy,sans-serif;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  color: #ffd180;
  margin: 0 0 91px;
  text-align: center;
}
.case.pyoor .case-galleryImage {
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(500px,1fr));
  grid-row-gap: 40px;
}
.case-galleryImage__image {
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.case-galleryImage__source {
  width: 93%;
  -o-object-fit: contain;
  object-fit: contain;
}
.case.pyoor .case-galleryImage {
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(500px,1fr));
  grid-row-gap: 40px;
}
.case.pyoor .case-gallery__block:nth-of-type(2) .case-galleryImage {
  grid-template-columns: repeat(2,1fr);
  grid-row-gap: 36px;
}
.case-galleryImage__image {
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.case-next {
  padding-top: 108px;
  padding-bottom: 108px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.case-next__title {
  font-size: 24px;
  line-height: 29px;
  color: #a9b1cc;
  margin: 0 0 8px;
}
.case-next__link, .case-next__title {
  font-family: Gilroy,sans-serif;
  font-weight: 700;
}
.case-next__link, .case-next__title {
  font-family: Gilroy,sans-serif;
  font-weight: 700;
}
.case-next__link {
  margin: 0;
  font-size: 64px;
  line-height: 70px;
  color: #1958e3;
  letter-spacing: -.015em;
  text-decoration: none;
  position: relative;
}
.case-next__link:hover:after {
  width: 100%;
}
.case-next__link:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 5px;
  background-color: #1958e3;
  transition: .4s;
}
.case-more {
  padding-top: 108px;
  padding-bottom: 108px;
  background-color: #f5f8ff;
}
.case-more__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.case-more__title {
  font-family: Gilroy,sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #a9b1cc;
  margin: 0 0 48px;
}
.case-more__block {
  display: grid;
  grid-template-columns: repeat(5,auto);
  grid-column-gap: 86px;
}
.case-more__link {
  margin: 0;
  font-family: Gilroy,sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #1958e3;
  text-decoration: none;
  position: relative;
}
.case-more__link:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #1958e3;
  transition: .4s;
}
.case-more__link:hover:after {
  width: 100%;
}
.privacySection{
  background-color: #f5f8ff;
}
.privacySection .privacySectionBox{
  max-width: 728px;
  margin   : 0px auto;
  padding  : 80px 0px;
}
@media (max-width:767px){
  .whywe {
      padding-left: 15px;
      padding-right: 15px;
  }
  .teams-card {
    display: block;
  }
  .teams-card__item {
    padding: 80px 0px;
  }
  .teams-card__item {
    padding: 10px;
    height: auto;
  }
  .teams-card__title {
    font-size: 20px;
    line-height: 33px;
  }
}




@media (max-width:1140px) {
	.cookies__wrapper {
		padding: 24px 16px;
		width: calc(100% - 32px);
		flex-direction: column;
		align-items: flex-start
	}
	.cookies__cat {
		margin-bottom: 8px
	}
	.cookies__text {
		margin-bottom: 16px
	}
	.cookies__text_span {
		display: block
	}
	.cookies__button {
		width: 100%
	}
	.header-burger {
		padding: 24px 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		transition: .4s
	}
	.header-burger__wrapper {
		display: flex;
		flex-direction: column;
		cursor: pointer
	}
	.header-burger__wrapper_dark .header-burger__item {
		background-color: #0f0f0f
	}
	.header-burger__item {
		margin-bottom: 7px;
		width: 36px;
		height: 3px;
		background-color: #fff;
		transition: .4s
	}
	.header-burger__item:last-of-type {
		margin: 0
	}
	.header .nav {
		position: fixed;
		width: 100%;
		height: 100%;
		min-height: 100vh;
		right: -100%;
		justify-content: unset;
		align-items: unset;
		flex-direction: column;
		transition: .4s;
		background-color: #1958e3
	}
	.header .nav-list {
		padding-left: 16px;
		padding-right: 16px;
		margin-top: 16px;
		display: grid;
		grid-column-gap: 88px;
		grid-template-columns: auto;
		grid-row-gap: 16px
	}
	.header .nav-list__link {
		margin: 0;
		font-family: Gilroy, sans-serif;
		font-weight: 700;
		font-size: 32px;
		line-height: 35px;
		color: #fff
	}
	.header .nav-list__link:hover {
		-webkit-transform: none;
		transform: none
	}
	.header .nav-list__link:after {
		display: none
	}
	.header .nav-list__item {
		margin-right: 0
	}
	.header .nav__button {
		margin-top: 32px;
		margin-bottom: 24px;
		margin-left: 16px;
		padding: 17px 36px;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
		border: 1px solid #fff;
		box-sizing: border-box;
		background-color: hsla(0, 0%, 100%, .1)
	}
	.header .nav__contact {
		padding-left: 16px;
		padding-right: 16px;
		font-family: Gilroy, sans-serif;
		font-weight: 700;
		font-size: 24px;
		line-height: 34px;
		color: #fff;
		margin: 0 0 6px;
		text-decoration: none
	}
	.header .movadexLogo {
		position: relative
	}
	.header .movadexLogo__icon {
		margin-right: 0;
		width: 28px;
		position: absolute;
		transition: .4s;
		left: 0
	}
	.header .movadexLogo__text {
		margin-left: 36px;
		width: 129px;
		transition: .4s
	}
	.header-open .header-burger {
		background-color: #1958e3;
		border-bottom: 1px solid hsla(0, 0%, 100%, .7)
	}
	.header-open .header-burger__item:first-of-type {
		-webkit-transform: rotate(135deg) translate(5px, -10px);
		transform: rotate(135deg) translate(5px, -10px)
	}
	.header-open .header-burger__item:nth-of-type(2) {
		opacity: 0;
		-webkit-transform: translateX(200%);
		transform: translateX(200%)
	}
	.header-open .header-burger__item:nth-of-type(3) {
		-webkit-transform: rotate(-135deg) translate(4px, 9px);
		transform: rotate(-135deg) translate(4px, 9px)
	}
	.header-open .movadexLogo__icon {
		left: -100%
	}
	.header-open .movadexLogo__text {
		margin-left: 0
	}
	.header-open .nav {
		right: 0
	}
	.footer-mail {
		padding: 96px 16px 30px
	}
	.footer-mail__wrapper {
		grid-template-columns: auto;
		grid-row-gap: 64px
	}
	.footer-mail__subtitle {
		margin-bottom: 32px;
		font-size: 18px;
		line-height: 22px
	}
	.footer-mail__title {
		margin: 0;
		font-size: 36px;
		line-height: 40px
	}
	.footer-mail__text {
		display: none
	}
	.footer-mail .footer-form__block:first-of-type {
		margin-bottom: 24px;
		grid-template-columns: 1fr;
		grid-row-gap: 24px
	}
	.footer-mail .footer-form__block:nth-of-type(2) {
		margin-bottom: 40px;
		flex-direction: column;
		align-items: flex-start;
		justify-content: unset
	}
	.footer-mail .footer-form__block:nth-of-type(3) {
		grid-template-columns: auto;
		grid-row-gap: 16px
	}
	.footer-mail .footer-form-checkbox {
		margin-bottom: 24px
	}
	.footer-mail .footer-form-checkbox__text {
		max-height: 100%
	}
	.footer-mail__cat {
		margin-top: 56px;
		align-self: flex-start
	}
	.footer-mailDone {
		padding-top: 100px;
		padding-bottom: 100px
	}
	.footer-mailDone__sent {
		font-size: 32px
	}
	.footer-main {
		padding-top: 64px;
		padding-bottom: 30px
	}
	.footer-main__wrapper:first-child {
		padding-left: 16px;
		padding-right: 16px;
		margin-bottom: 48px;
		flex-direction: column
	}
	.footer-main__wrapper:nth-child(2) {
		flex-direction: column
	}
	.footer-main__wrapper:nth-child(3) {
		padding-left: 16px;
		padding-right: 16px;
		margin-top: 48px;
		display: flex;
		flex-wrap: wrap
	}
	.footer-main__title {
		margin-bottom: 48px;
		font-size: 48px;
		line-height: 53px
	}
	.footer-main-nav {
		display: flex;
		flex-wrap: wrap
	}
	.footer-main-nav__list {
		margin-bottom: 48px;
		margin-right: 80px
	}
	.footer-main-nav__item:first-child {
		font-size: 16px;
		font-weight: 700;
		line-height: 21px
	}
	.footer-main-nav-media {
		width: 100%;
		justify-content: center
	}
	.footer-main-nav-media__link {
		min-width: 44px;
		min-height: 44px
	}
	.footer-main-nav-media__link:last-of-type {
		margin-right: 0
	}
	.footer-main__world_flex {
		margin-left: 0
	}
	.footer-main-info {
		padding-left: 16px;
		padding-right: 16px;
		order: 1
	}
	.footer-main-info__text {
		margin-bottom: 24px;
		line-height: 29px;
		max-width: 100%
	}
	.footer-main-info__block {
		margin-bottom: 32px;
		grid-template-columns: auto;
		grid-row-gap: 24px
	}
	.footer-main-info__link:first-of-type {
		margin: 0
	}
	.footer-main__world {
		order: 0;
		width: 100%;
		margin-bottom: 48px
	}
	.footer-main__data {
		font-weight: 700
	}
	.footer-main__data:first-child {
		margin-bottom: 16px;
		width: 100%
	}
	.footer-main__data:nth-child(2) {
		margin-right: 36px
	}
	.footer-rights {
		padding: 24px 16px
	}
}

@media (max-width:1380px) and (min-width:1140px) {
	.container {
		max-width: 97%
	}
}

@media (max-width:1181px) and (min-width:1140px) {
	.footer-mail__wrapper {
		grid-column-gap: 70px
	}
}

@media (max-width:1140px) {
	.notfound {
		padding: 0
	}
	.notfound__wrapper {
		padding: 48px 15px;
		border: none
	}
	.notfound__image {
		width: 100%
	}
}


@media (max-width:1140px) {
	.whywe {
		padding-left: 16px;
		padding-right: 16px;
		padding-top: 128px
	}
	.whywe__block {
		margin-bottom: 32px;
		grid-template-columns: auto
	}
	.whywe__title {
		font-size: 48px;
		line-height: 53px
	}
	.whywe .slider {
		height: auto;
		overflow: auto
	}
	.whywe .slider__wrapper {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
		grid-column-gap: 24px;
		grid-row-gap: 24px;
		position: inherit
	}
}

@media (max-width:1140px) and (max-width:340px) {
	.whywe .slider__wrapper {
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr))
	}
}

@media (max-width:1140px) {
	.whywe .slider-item {
		margin: 0;
		padding: 32px 36px 32px 24px;
		width: auto
	}
	.whywe .slider-item__title {
		margin-bottom: 16px
	}
	.whywe .slider-item__text {
		max-width: 100%
	}
	.ourhistory {
		padding-left: 16px;
		padding-right: 16px;
		padding-top: 48px
	}
	.ourhistory__title {
		font-size: 48px;
		line-height: 53px;
		margin-bottom: 32px
	}
	.ourhistory-item {
		margin-bottom: 64px;
		grid-row-gap: 24px
	}
	.ourhistory-item__text {
		max-width: 100%
	}
	.ourhistory-item__text:before {
		display: none
	}
	.ourhistory-item__image {
		width: 100%;
		max-height: 343px;
		-o-object-fit: cover;
		object-fit: cover;
		-o-object-position: top;
		object-position: top
	}
	.ourhistory-item:first-of-type {
		grid-template-columns: auto
	}
	.ourhistory-item:nth-of-type(2) {
		grid-template-columns: auto;
		justify-content: unset
	}
	.ourhistory-item:nth-of-type(2) .ourhistory-item__text {
		order: 1
	}
	.ourhistory-item:nth-of-type(2) .ourhistory-item__source {
		order: 0
	}
	.ourhistory-item:nth-of-type(3) {
		margin-bottom: 0;
		grid-template-columns: auto
	}
	.ourmission {
		padding: 48px 16px
	}
	.ourmission__title {
		margin-bottom: 48px;
		font-size: 48px;
		line-height: 53px
	}
	.ourmission__block {
		grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
		grid-row-gap: 48px
	}
}

@media (max-width:1140px) and (max-width:340px) {
	.ourmission__block {
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr))
	}
}

@media (max-width:1140px) {
	.ourmission-item__text {
		font-size: 16px;
		line-height: 21px
	}
	.teams {
		padding-bottom: 0
	}
	.teams__title {
		padding-left: 16px;
		padding-right: 16px;
		font-size: 48px;
		line-height: 53px
	}
	.teams-card {
		margin-top: 32px;
		grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
		grid-column-gap: 0;
		grid-row-gap: 0
	}
	.teams-card__text {
		max-width: 91%
	}
}

@media (max-width:500px) {
	.teams-card {
		grid-template-columns: repeat(auto-fit, minmax(320px, 1fr))
	}
}

@media (max-width:500px) and (max-width:340px) {
	.teams-card {
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr))
	}
}

@media (max-width:500px) {
	.teams-card__content {
		padding: 20px
	}
}

@media (max-width:374px) {
	.teams-card__item {
		height: 285px
	}
	.teams-card__title {
		width: auto;
		font-size: 32px;
		line-height: 110%
	}
	.teams-card__text {
		max-width: 90%
	}
}

@media (max-width:1140px) {
	.blog {
		padding-top: 128px
	}
	.blog__title {
		padding-left: 16px;
		padding-right: 16px;
		margin-bottom: 48px;
		font-size: 48px;
		line-height: 53px
	}
	.blog-head {
		padding: 44px 16px 55px;
		height: auto
	}
	.blog__subtitle {
		margin-bottom: 24px;
		font-size: 24px;
		line-height: 29px;
		padding: 0 16px
	}
	.blog__block {
		grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
		margin-bottom: 40px;
		grid-gap: 56px 0
	}
}

@media (max-width:1140px) and (max-width:340px) {
	.blog__block {
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr))
	}
}

@media (max-width:1140px) {
	.blog-item {
		padding-left: 16px;
		padding-right: 16px
	}
	.blog-more__btn {
		font-size: 24px;
		line-height: 29px
	}
	.category-filter {
		padding: 0 16px
	}
}

@media (max-width:1000px) {
	.blog__block.row-list {
		overflow: auto;
		grid-template-columns: 600px 600px
	}
	.blog__block.row-list::-webkit-scrollbar {
		width: 8px
	}
	.blog__block.row-list::-webkit-scrollbar-track {
		background: transparent
	}
	.blog__block.row-list::-webkit-scrollbar-thumb {
		background-color: #1958e3;
		border-radius: 20px;
		border: 5px solid #fff
	}
	.blog .blog-head-img {
		height: 350px
	}
	.blog-item__block:first-of-type {
		height: 200px
	}
	.blog .row-list .blog-item__block:first-of-type {
		height: 250px
	}
	.blog-head {
		display: flex;
		flex-direction: column;
		margin-bottom: 0;
		padding: 0 16px 44px
	}
}

@media (max-width:750px) {
	.blog-head {
		grid-gap: 16px;
		min-height: unset
	}
	.blog .blog-head-content {
		display: grid;
		justify-content: center;
		text-align: center
	}
	.blog-head .blog-head-subtitle {
		display: none
	}
	.blog-head .blog-head-tags {
		justify-content: center
	}
	.blog .blog-item__shorttext {
		display: none
	}
	.blog .blog-head-tag {
		line-height: 157%
	}
	.blog-head-content .blog-item__shorttext {
		display: -webkit-box;
		font-size: 16px;
		height: 46px;
		line-height: 23px;
		color: #1e1e1e
	}
	.blog-head .blog-head-date {
		margin-top: 16px;
		grid-row: 2
	}
	.blog .blog-head-title {
		margin: 0;
		font-weight: 700;
		line-height: 28.8px
	}
	.blog .blog-head-title,
	.blog .row-list .blog-item__title {
		font-family: Gilroy, sans-serif;
		font-size: 24px;
		color: #0f0f0f
	}
	.blog .row-list .blog-item__title {
		font-weight: 600;
		line-height: 120%;
		margin: 16px 0 0
	}
	.blog .blog-list .blog-head-date {
		margin-top: 8px;
		font-size: 14px;
		line-height: 140%
	}
	.blog__block.row-list {
		grid-template-columns: 80% 80%;
		margin: 0 8px 40px
	}
	.blog-head-tags.category-filter {
		flex-wrap: unset;
		margin: 0 16px 36px;
		padding: 0
	}
	.blog-head-tags.category-filter>:first-child {
		margin-left: 0
	}
	.blog-item__title {
		font-size: 24px;
		line-height: 120%
	}
	.blog .blog-list .blog-head-tags {
		margin-top: 0
	}
	.blog-item__block:first-of-type,
	.blog .blog-head-img,
	.blog .row-list .blog-item__block:first-of-type {
		height: 193px
	}
	.row-list .blog-item {
		padding: 0 8px
	}
}

@media (max-width:500px) {
	.blog-item__block:first-of-type {
		height: auto
	}
}

@media (max-width:1140px) {
	.modal {
		padding-left: 16px;
		padding-right: 16px
	}
	.modal__wrapper {
		padding: 29px 18px 23px 17px
	}
	.modal__close {
		width: 16px;
		height: 16px;
		top: 20px;
		right: 20px
	}
	.modal__title {
		margin-bottom: 24px;
		max-width: 90%
	}
	.modal-form {
		width: 100%;
		grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
		grid-column-gap: 15px;
		grid-row-gap: 15px
	}
}

@media (max-width:1140px) and (max-width:340px) {
	.modal-form {
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr))
	}
}

@media (max-width:1140px) {
	.modal-form__mail,
	.modal-form__name,
	.modal-form__phone {
		grid-area: unset
	}
	.modal-form__text {
		grid-area: unset;
		margin: 0;
		min-height: 90px
	}
	.modal-form__submit {
		grid-area: unset;
		order: 1
	}
	.modal-form-file {
		grid-area: unset;
		justify-content: flex-start;
		order: 0
	}
	.career {
		padding: 128px 16px 56px
	}
	.career__title {
		margin-bottom: 32px;
		font-size: 48px;
		line-height: 53px;
		max-width: 100%
	}
	.career__subtitle {
		margin-bottom: 32px;
		font-size: 24px;
		line-height: 29px
	}
	.career-info {
		margin-bottom: 48px
	}
	.career-info__text {
		font-size: 16px;
		line-height: 22px;
		letter-spacing: .05em;
		max-width: 100%
	}
	.career-info__text:first-of-type {
		margin-bottom: 24px
	}
	.career-info__text:nth-of-type(2) {
		margin-bottom: 0
	}
	.career-job {
		grid-template-columns: repeat(auto-fit, minmax(320px, 1fr))
	}
}

@media (max-width:1140px) and (max-width:340px) {
	.career-job {
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr))
	}
}

@media (max-width:1140px) {
	.career-job__block:first-child {
		padding: 32px 27px 48px 24px
	}
	.career-job__block:nth-child(2) {
		padding: 17px 33px 17px 24px
	}
	.career-job__title {
		margin-bottom: 24px;
		font-size: 24px;
		line-height: 29px
	}
	.whywork {
		padding-left: 16px;
		padding-right: 16px
	}
	.whywork__title {
		font-size: 48px;
		line-height: 53px
	}
	.whywork__wrapper {
		grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
		grid-column-gap: 64px;
		grid-row-gap: 64px
	}
}

@media (max-width:1140px) and (max-width:340px) {
	.whywork__wrapper {
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr))
	}
}

@media (max-width:1140px) {
	.whywork-item {
		flex-direction: column;
		overflow: hidden
	}
	.whywork-item__title {
		margin-right: 0;
		margin-bottom: 24px;
		position: inherit
	}
	.whywork-item__block {
		margin-left: 0
	}
	.whywork-item__text {
		margin-bottom: 24px;
		letter-spacing: .05em
	}
}

@media (max-width:340px) {
	.modal__title {
		font-size: 20px;
		line-height: 110%
	}
	.modal-form__mail,
	.modal-form__name,
	.modal-form__phone,
	.modal-form__text {
		padding: 10px;
		min-width: 100%;
		font-size: 16px;
		line-height: 110%
	}
	.modal-form-file__span {
		margin-right: 12px
	}
	.modal-form-file__icon {
		width: 36px;
		height: 36px
	}
	.modal-form-file__text {
		font-size: 16px;
		line-height: 110%
	}
	.modal-form__submit {
		padding: 14px
	}
	.career__title {
		font-size: 41px;
		line-height: 110%
	}
}


@media (max-width:1140px) {
	.home {
		padding-top: 97px;
	}
	.home__title {
		font-size: 40px;
		line-height: 44px;
		color: #fff;
		-webkit-text-fill-color: #fff;
		background: none
	}
	.home__text,
	.home__title {
		padding-left: 16px;
		padding-right: 16px;
		margin-bottom: 24px
	}
	.home-link {
		margin-bottom: 62px;
		margin-left: 16px;
		margin-right: 16px
	}
	.home-card {
		display: flex;
		overflow: auto;
		box-sizing: border-box
	}
	.home-card::-webkit-scrollbar {
		display: none
	}
	.home-card__item {
		padding: 19px 16px;
		min-width: 270px;
		position: relative
	}
	.home-card__item:after {
		content: "";
		width: 100%;
		height: 100%;
		display: flex;
		position: absolute;
		top: 0;
		left: 0;
		opacity: .7
	}
	.home-card__item:first-of-type:after {
		background: linear-gradient(90deg, #da22ff, #9733ee)
	}
	.home-card__item:nth-of-type(2):after {
		background: linear-gradient(90deg, #ec008c, #fc6767)
	}
	.home-card__item:nth-of-type(3):after {
		background: linear-gradient(90deg, #e65c00, #f9d423)
	}
	.home-card__item:nth-of-type(4):after {
		background: linear-gradient(90deg, #0052d4, #4364f7 50%, #6fb1fc)
	}
	.home-card__item:first-child,
	.home-card__item:nth-child(2),
	.home-card__item:nth-child(3),
	.home-card__item:nth-child(4) {
		border: none
	}
	.home-card__item:first-child:hover,
	.home-card__item:nth-child(2):hover,
	.home-card__item:nth-child(3):hover,
	.home-card__item:nth-child(4):hover {
		background: none
	}
	.home-card__item:first-child .home-card__title,
	.home-card__item:nth-child(2) .home-card__title,
	.home-card__item:nth-child(3) .home-card__title,
	.home-card__item:nth-child(4) .home-card__title {
		-webkit-text-fill-color: #fff
	}
	.home-card__title {
		margin: 0;
		font-size: 64px;
		line-height: 70px;
		letter-spacing: -.015em;
		z-index: 1;
		position: inherit
	}
	.home-card__subtitle {
		font-size: 18px;
		line-height: 23px;
		letter-spacing: .01em;
		z-index: 1;
		position: inherit
	}
	.services {
		padding: 64px 16px 80px;
		background-image: none
	}
	.services__title {
		margin-bottom: 32px;
		font-size: 48px;
		line-height: 53px;
		position: inherit;
		top: 0
	}
	.services__text {
		margin-bottom: 48px;
		font-size: 16px;
		line-height: 22px;
		letter-spacing: .05em;
		position: inherit;
		top: 0
	}
	.services-slider {
		height: auto;
		overflow: auto
	}
	.services-slider__wrapper {
		position: inherit;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
		grid-column-gap: 16px;
		grid-row-gap: 16px
	}
}

@media (max-width:1140px) and (max-width:340px) {
	.services-slider__wrapper {
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr))
	}
}

@media (max-width:1140px) {
	.services-slider__item {
		margin-right: 0;
		padding: 32px 40px 40px
	}
	.services-slider__item.active:hover .services-slider__text,
	.services-slider__item.active:hover .services-slider__title {
		-webkit-transform: none;
		transform: none
	}
	.services-slider-svg {
		width: 48px
	}
	.services-slider__title {
		margin-top: 0;
		margin-bottom: 16px;
		font-size: 28px;
		line-height: 34px
	}
	.services-slider__text {
		font-size: 16px;
		line-height: 22px;
		letter-spacing: .05em;
		max-width: 100%
	}
	.services-slider-more {
		margin-top: 32px;
		opacity: .5;
		position: inherit
	}
	.stack {
		padding-top: 64px;
		padding-bottom: 64px
	}
	.stack__wrapper {
		padding-left: 16px;
		padding-right: 16px;
		flex-direction: column
	}
	.stack__block {
		display: flex;
		flex-direction: column
	}
	.stack__title {
		margin-bottom: 40px;
		font-size: 48px;
		line-height: 53px
	}
	.stack-list {
		margin-bottom: 48px;
		order: 1;
		display: flex;
		overflow-x: auto
	}
	.stack-list__item {
		margin: 0 63px 0 0;
		width: -webkit-min-content;
		width: min-content
	}
	.stack-list__item:before {
		display: none
	}
	.stack-slider {
		grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
		grid-column-gap: 16px;
		grid-row-gap: 16px
	}
	.stack-slider__item {
		padding: 35px
	}
	.stack-slider__item:last-of-type {
		position: relative
	}
	.stack-slider__cat {
		width: 83px;
		position: absolute;
		bottom: 0;
		right: 15px
	}
	.stack-slider__icon {
		margin-bottom: 10px;
		width: 57px;
		height: 57px;
		-o-object-fit: contain;
		object-fit: contain
	}
	.stack-slider__title {
		font-size: 18px;
		line-height: 22px;
		text-align: center
	}
	.stack__text {
		margin-bottom: 48px;
		order: 0;
		max-width: 100%
	}
	.awards {
		padding: 48px 16px 64px
	}
	.awards__wrapper {
		grid-template-columns: auto
	}
	.awards__block:nth-of-type(2) {
		margin-left: unset;
		width: unset;
		justify-content: unset;
		height: unset;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
		grid-column-gap: 24px;
		grid-row-gap: 24px
	}
}

@media (max-width:1140px) and (max-width:340px) {
	.awards__block:nth-of-type(2) {
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr))
	}
}

@media (max-width:1140px) {
	.awards-card {
		margin-right: 0;
		width: auto;
		height: 220px;
		position: relative
	}
	.awards-card:hover {
		width: auto;
		height: 264px
	}
	.awards-card__text {
		padding-right: 20px;
		width: auto
	}
	.awards-card:first-of-type:hover .awards-card__logo {
		top: 20px;
		left: 20px
	}
	.awards__subtitle {
		margin-bottom: 16px;
		font-size: 18px;
		line-height: 22px
	}
	.awards__title {
		margin-bottom: 32px;
		font-size: 48px;
		line-height: 53px
	}
	.awards__text {
		margin-bottom: 48px;
		font-size: 16px;
		line-height: 22px;
		letter-spacing: .05em
	}
	.clients__block {
		grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
		width: 100%
	}
}

@media (max-width:1140px) and (max-width:340px) {
	.clients__block {
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr))
	}
}

@media (max-width:1140px) {
	.clients__link {
		padding: 14px 35px;
		font-size: 25px;
		font-weight: 110%
	}
	.reviews {
		padding-left: 16px;
		padding-right: 16px;
		padding-top: 64px
	}
	@-webkit-keyframes reviewsFade {
		0% {
			opacity: .5
		}
		to {
			opacity: 1
		}
	}
	@keyframes reviewsFade {
		0% {
			opacity: .5
		}
		to {
			opacity: 1
		}
	}
	.reviews__wrapper {
		flex-direction: column;
		max-width: 100%
	}
	.reviews__title {
		margin-bottom: 64px;
		max-width: 100%;
		font-size: 48px;
		line-height: 53px;
		position: inherit
	}
	.reviews__block {
		margin-left: 0;
		max-width: 100%
	}
	.reviews-step {
		margin-bottom: 24px;
		font-size: 24px;
		line-height: 29px
	}
	.reviews-view {
		-webkit-animation-name: reviewsFade;
		animation-name: reviewsFade;
		-webkit-animation-iteration-count: 1;
		animation-iteration-count: 1;
		-webkit-animation-duration: .8s;
		animation-duration: .8s
	}
	.reviews-view__subtitle {
		margin-bottom: 32px
	}
	.reviews-view__text {
		margin-bottom: 40px;
		max-width: 100%
	}
	.whyus {
		padding: 64px 16px 115px
	}
	.whyus__title {
		margin-bottom: 40px;
		font-size: 48px;
		line-height: 53px
	}
	.whyus_border {
		border: none
	}
	.whyus-card {
		grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
		grid-column-gap: 48px;
		grid-row-gap: 48px
	}
}

@media (max-width:1140px) and (max-width:340px) {
	.whyus-card {
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr))
	}
}

@media (max-width:1140px) {
	.whyus-card__item {
		padding: 0;
		justify-content: unset
	}
	.whyus-card__item:first-child,
	.whyus-card__item:nth-child(3) {
		border: none
	}
	.whyus-card_flex {
		order: 1
	}
	.whyus-card__index {
		order: 0;
		margin-top: 0;
		margin-bottom: 24px
	}
	.whyus-card__title {
		font-size: 28px;
		line-height: 34px
	}
	.whyus-card__text {
		font-size: 16px;
		line-height: 22px;
		letter-spacing: .05em
	}
	.portfolio {
		padding-top: 48px;
		padding-bottom: 24px
	}
	.portfolio__subtitle {
		padding-left: 16px;
		padding-right: 16px;
		margin-bottom: 16px;
		font-size: 18px;
		line-height: 22px
	}
	.portfolio__title {
		margin-bottom: 32px;
		font-size: 48px;
		line-height: 53px
	}
	.portfolio__text,
	.portfolio__title {
		padding-left: 16px;
		padding-right: 16px
	}
	.portfolio__text {
		margin-bottom: 48px;
		max-width: 100%
	}
	.portfolio__block {
		grid-template-columns: repeat(auto-fit, minmax(320px, 1fr))
	}
}

@media (max-width:1140px) and (max-width:340px) {
	.portfolio__block {
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr))
	}
}

@media (max-width:1140px) {
	.portfolio-item {
		grid-column-gap: 24px;
		grid-row-gap: 24px;
		padding: 26px;
		height: 250px
	}
	.portfolio-item:first-of-type,
	.portfolio-item:nth-of-type(2),
	.portfolio-item:nth-of-type(3),
	.portfolio-item:nth-of-type(4),
	.portfolio-item:nth-of-type(5),
	.portfolio-item:nth-of-type(6),
	.portfolio-item:nth-of-type(7),
	.portfolio-item:nth-of-type(8),
	.portfolio-item:nth-of-type(9),
	.portfolio-item:nth-of-type(10),
	.portfolio-item:nth-of-type(11),
	.portfolio-item:nth-of-type(12) {
		grid-area: unset;
		background-size: auto
	}
	.portfolio-item:first-of-type {
		background-image: url("https://d33wubrfki0l68.cloudfront.net/b75d5fdf72c785b23eef0bf51c0ff0f005b2464e/2837e/static/m-portfolio-8-f91fc97ab05734472099a8128cbbe32a.png");
		background-position: bottom;
		background-color: #6734df;
		background-size: 100%, auto!important
	}
	.portfolio-item:nth-of-type(2) {
		background-image: url("https://d33wubrfki0l68.cloudfront.net/cafbf431c7ca9f205c592b25b495597f9963ac09/79b79/static/m-portfolio-9-9c8510f88d6a5141464073a18b2ce745.png"), linear-gradient(180deg, #1dbf73, #1ec099);
		background-size: 100%, auto!important
	}
	.portfolio-item:nth-of-type(3) {
		background-image: url("https://d33wubrfki0l68.cloudfront.net/9b0e7772e41ed73c3cd54a6e09350debb257e59d/0da9c/static/m-portfolio-10-c04859136c542daac3fa9857539d1cef.png");
		background-color: #f2c94c;
		background-size: 100%, auto!important
	}
	.portfolio-item:nth-of-type(4) {
		background: url("https://d33wubrfki0l68.cloudfront.net/dadfcc14d64df00404fa32e39eb45640040f5c86/2d373/static/m-portfolio-11-dc41d3ec1774a174d4ca2f1c443c7d31.png"), linear-gradient(180deg, rgba(97, 0, 255, .2), rgba(0, 0, 0, .2)), #121214;
		background-repeat: no-repeat;
		background-position: bottom;
		background-size: 100%, auto!important
	}
	.portfolio-item:nth-of-type(5) {
		background-image: url("https://d33wubrfki0l68.cloudfront.net/a5296bf6269394be33f6b5114c5a08c370e1c11b/83b9b/static/m-portfolio-12-0c0055f2d545575715de3b44e9ffcc1b.png");
		background-color: #001a41;
		background-size: 100%, auto!important
	}
	.portfolio-item:nth-of-type(6) {
		background-image: url("https://d33wubrfki0l68.cloudfront.net/95c8651c8e4b5617bfadc8bf45157ca2003acb2c/b5e2e/static/m-portfolio-1-a6b0d08cc7285d412bd23f6f6fdebb74.png"), linear-gradient(90deg, #4481eb, #04befe)
	}
	.portfolio-item:nth-of-type(7) {
		background-image: url("https://d33wubrfki0l68.cloudfront.net/84cfb77fba890347ea7e0d02e4068d5bd24e6fb2/16149/static/m-portfolio-2-444767cf6eb3e88ada79c1a5c05acc10.png"), linear-gradient(90deg, #04c680, #44c971);
		background-position: center 105%, 50%
	}
	.portfolio-item:nth-of-type(8) {
		background-image: url("https://d33wubrfki0l68.cloudfront.net/dda4e9cf5f866bd1922c2e2d08629592bcb31979/1ed4f/static/m-portfolio-3-5ccd31fdfaaef605317fd5334134aaef.png"), linear-gradient(232.06deg, #e319b7, #e93152);
		background-position: bottom
	}
	.portfolio-item:nth-of-type(9) {
		background-image: url("https://d33wubrfki0l68.cloudfront.net/768ae749e8c17fd946cb2489078d6672742954f2/cfe3a/static/m-portfolio-5-2678d3c84d8b1ee2e52371b2942c82ad.png"), linear-gradient(90deg, #04c680, #44c971);
		background-position: center 101%, 50%
	}
	.portfolio-item:nth-of-type(10) {
		background-image: url("https://d33wubrfki0l68.cloudfront.net/38242d0cf2f76cf3747fbf43ba7b02cfe4185044/de0e7/static/m-portfolio-6-0d2ac9a3efaf7316181e0a5deb21c333.png"), linear-gradient(90deg, #06f, #6e3dfa)
	}
	.portfolio-item:nth-of-type(11) {
		background-image: url("https://d33wubrfki0l68.cloudfront.net/7f69ab5e95a15f917d229ecb3f6cb9fef526934c/0ef33/static/m-portfolio-7-6325d24ff0874a3608bdbb984eee5d29.png"), linear-gradient(90deg, #434343, #000)
	}
	.portfolio-item:nth-of-type(12) {
		background-size: 0, 100%
	}
	.portfolio-item:nth-of-type(12):after {
		display: none
	}
	.portfolio-item:first-of-type,
	.portfolio-item:nth-of-type(2),
	.portfolio-item:nth-of-type(3),
	.portfolio-item:nth-of-type(4),
	.portfolio-item:nth-of-type(5),
	.portfolio-item:nth-of-type(6),
	.portfolio-item:nth-of-type(7),
	.portfolio-item:nth-of-type(8),
	.portfolio-item:nth-of-type(9),
	.portfolio-item:nth-of-type(10),
	.portfolio-item:nth-of-type(11),
	.portfolio-item:nth-of-type(12) {
		background-size: 90%, auto
	}
	.news {
		padding-top: 54px;
		padding-bottom: 0;
		border-bottom: none
	}
	.news__subtitle {
		margin-bottom: 16px;
		font-size: 18px;
		line-height: 22px
	}
	.news__subtitle,
	.news__title {
		padding-left: 16px;
		padding-right: 16px
	}
	.news__title {
		font-size: 48px;
		line-height: 53px
	}
	.news__link {
		padding-top: 25px;
		padding-bottom: 25px;
		font-size: 24px;
		line-height: 29px;
		color: #fff;
		background-color: #1958e3
	}
	.news__link:after {
		display: none
	}
}

@media (min-width:905px) and (max-width:1007px) {
	.portfolio-item:first-of-type,
	.portfolio-item:nth-of-type(2),
	.portfolio-item:nth-of-type(3),
	.portfolio-item:nth-of-type(4),
	.portfolio-item:nth-of-type(5),
	.portfolio-item:nth-of-type(6),
	.portfolio-item:nth-of-type(7) {
		background-position: center 143%, 50%
	}
}

@media (min-width:500px) and (max-width:1140px) {
	.stack-slider {
		grid-template-columns: repeat(3, 1fr)
	}
}

@media (min-width:580px) and (max-width:663px) {
	.portfolio-item:first-of-type,
	.portfolio-item:nth-of-type(2),
	.portfolio-item:nth-of-type(3),
	.portfolio-item:nth-of-type(4),
	.portfolio-item:nth-of-type(5),
	.portfolio-item:nth-of-type(6),
	.portfolio-item:nth-of-type(7) {
		background-position: center 145%, 50%;
		background-size: 66%, auto
	}
}

@media (min-width:500px) and (max-width:580px) {
	.portfolio-item:first-of-type,
	.portfolio-item:nth-of-type(2),
	.portfolio-item:nth-of-type(3),
	.portfolio-item:nth-of-type(4),
	.portfolio-item:nth-of-type(5),
	.portfolio-item:nth-of-type(6),
	.portfolio-item:nth-of-type(7) {
		background-position: center 135%, 50%;
		background-size: 80%, auto
	}
}

@media (min-width:450px) and (max-width:500px) {
	.portfolio-item:first-of-type,
	.portfolio-item:nth-of-type(2),
	.portfolio-item:nth-of-type(3),
	.portfolio-item:nth-of-type(4),
	.portfolio-item:nth-of-type(5),
	.portfolio-item:nth-of-type(6),
	.portfolio-item:nth-of-type(7) {
		background-position: center 114%, 50%;
		background-size: 80%, auto
	}
}

@media (max-width:500px) {
	.stack-slider {
		grid-template-columns: repeat(2, 1fr)
	}
}

@media (max-width:340px) {
	.stack-slider {
		grid-template-columns: 1fr;
		grid-auto-rows: 1fr
	}
}


@media (max-width:1140px) {
	.survey {
		padding: 128px 16px 48px
	}
	.survey__title {
		margin-bottom: 38px;
		font-size: 48px;
		line-height: 53px
	}
	.survey__subtitle {
		font-size: 24px;
		line-height: 29px
	}
	.quiz {
		padding-left: 16px;
		padding-right: 16px
	}
	.quiz__block:first-of-type {
		grid-template-columns: auto;
		grid-row-gap: 32px
	}
	.quiz__block:nth-of-type(2) {
		grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
		grid-column-gap: 16px;
		grid-row-gap: 16px
	}
	.quiz__title {
		order: 1;
		font-size: 24px;
		line-height: 29px
	}
	.quiz__step {
		order: 0;
		text-align: left
	}
	.quiz-item__title {
		font-size: 18px;
		line-height: 22px;
		text-align: center
	}
	.quiz-btn {
		margin-top: 40px;
		padding: 18px 22px;
		width: 100%;
		justify-content: space-between;
		font-size: 24px;
		line-height: 29px
	}
	.quiz-btn__svg {
		margin-left: 0
	}
	.quiz-btn:hover {
		padding-left: 32px
	}
	.quiz-btn:hover .quiz-btn__svg {
		-webkit-transform: scale(.9);
		transform: scale(.9)
	}
}

@media (min-width:1140px) {
	.quiz-item:hover .quiz-item__label {
		background-color: #1958e3
	}
	.quiz-item:hover .quiz-item__title {
		color: #fff
	}
	.quiz-item:hover .quiz-item__icon {
		-webkit-filter: brightness(26);
		filter: brightness(26)
	}
}

@media (max-width:374px) {
	.quiz-btn:hover {
		padding-left: 28px
	}
	.quiz-btn:hover .quiz-btn__svg {
		-webkit-transform: scale(.9);
		transform: scale(.9)
	}
}

@media (max-width:1140px) {
	.article-head {
		padding: 145px 16px 96px;
		height: auto
	}
	.article-head__title {
		margin-bottom: 30px
	}
	.article-info {
		padding-left: 16px;
		padding-right: 16px
	}
	.article-info__wrapper {
		display: grid;
		grid-template-columns: 1fr 101px;
		grid-template-rows: repeat(2, 1fr);
		grid-column-gap: 42px;
		grid-row-gap: 10px
	}
	.article-info__block:first-of-type {
		grid-area: 1/1/2/3
	}
	.article-info__block:first-of-type:after {
		display: none
	}
	.article-info__block:nth-of-type(2) {
		margin-left: 0
	}
	.article-item {
		padding-left: 16px;
		padding-right: 16px
	}
	.article-more {
		padding-top: 23px;
		padding-bottom: 25px
	}
	.article-more__title {
		margin-bottom: 25px;
		font-size: 24px;
		line-height: 29px
	}
}


@media (max-width:1140px) {
	.case.flight-simulator .case-head {
		background-image: url("https://d33wubrfki0l68.cloudfront.net/063eaff24836d541d18265350d1cb01d424189c2/bd6e0/images/cases/flight-simulator/background-sm.png");
		background-position: 100%
	}
	.case.flight-simulator .case-gallery__wrapper {
		grid-template-columns: 1fr
	}
	.case.flight-simulator .case-galleryImage {
		grid-template-columns: repeat(2, 1fr);
		grid-row-gap: 32px
	}
	.case.turtlequeue .case-head {
		background-image: url("https://d33wubrfki0l68.cloudfront.net/41e4dd964fcffa0bca31bab00f3d4913d90878d2/94051/images/cases/turtlequeue/background-sm.png");
		background-position: bottom
	}
	.case.turtlequeue .case-gallery__wrapper {
		grid-template-columns: 1fr
	}
	.case.turtlequeue .case-gallery__block:nth-of-type(2) .case-galleryImage {
		grid-template-columns: repeat(2, 1fr)
	}
	.case.startify .case-head {
		background-image: url("https://d33wubrfki0l68.cloudfront.net/c2da9325485c0b63bcb12614e6508a20203833ee/9a551/images/cases/startify/background-sm.png");
		background-position: bottom
	}
	.case.startify .case-gallery__wrapper {
		grid-template-columns: 1fr
	}
	.case.startify .case-gallery__block:first-of-type {
		grid-area: unset
	}
	.case.startify .case-gallery__block:first-of-type .case-galleryImage {
		grid-template-columns: 1fr;
		grid-row-gap: 24px
	}
	.case.startify .case-gallery__block:nth-of-type(3) .case-galleryImage {
		grid-template-columns: repeat(2, 1fr);
		grid-row-gap: 36px
	}
	.case.medicus-ug .case-head {
		background-image: url("https://d33wubrfki0l68.cloudfront.net/0ea539bee054adbd8b231934c814123b9a9f4c87/8fde5/images/cases/medicus-ug/background-sm.png");
		background-position: inherit
	}
	.case.medicus-ug .case-gallery__wrapper {
		grid-template-columns: 1fr
	}
	.case.medicus-ug .case-galleryImage {
		grid-template-columns: repeat(2, 1fr);
		grid-row-gap: 32px
	}
	.case.kyiv-metro-app .case-head {
		background-image: url("https://d33wubrfki0l68.cloudfront.net/ab2e6e5f7d5fd276b1f45cbe3d3875e6dba33501/6e584/images/cases/kyiv-metro-app/background-sm.png");
		background-position: bottom
	}
	.case.kyiv-metro-app .case-gallery__wrapper {
		grid-template-columns: 1fr
	}
	.case.kyiv-metro-app .case-galleryImage {
		grid-template-columns: repeat(2, 1fr);
		grid-row-gap: 32px
	}
	.case.astrolife .case-gallery__wrapper,
	.case.legends-pro .case-gallery__wrapper,
	.case.legends-pro .case-galleryImage,
	.case.plan-b .case-gallery__wrapper,
	.case.pyoor .case-gallery__wrapper,
	.case.pyoor .case-galleryImage {
		grid-template-columns: 1fr
	}
	.case.plan-b .case-galleryImage {
		grid-template-columns: 1fr 1fr
	}
	.case.klt-health .case-galleryImage__image {
		align-items: start
	}
	.case.klt-health .case-gallery__wrapper {
		grid-template-columns: 1fr
	}
	.case.uprice .case-head {
		background-image: url("https://d33wubrfki0l68.cloudfront.net/67aa9638f98691d342aac855883fe93e204681a8/1170e/images/cases/uprice/background-sm.png");
		background-position: bottom
	}
	.case.uprice .case-gallery__wrapper {
		grid-template-columns: 1fr
	}
	.case.uprice .case-galleryImage {
		grid-template-columns: repeat(2, 1fr);
		grid-row-gap: 32px
	}
	.case.znaj-ua .case-head {
		background-image: url("https://d33wubrfki0l68.cloudfront.net/53fc085da9d89a3e63be32e49e4cd70ff101a2e9/95f86/images/cases/znaj-ua/background-sm.png");
		background-position: 50%;
		background-size: cover
	}
	.case.znaj-ua .case-gallery__wrapper {
		grid-template-columns: 1fr
	}
	.case.znaj-ua .case-galleryImage {
		grid-template-columns: repeat(2, 1fr);
		grid-row-gap: 32px
	}
	.case-head {
		height: 100vh;
		min-height: 500px
	}
	.case-head__title {
		margin-top: 24px;
		margin-bottom: 24px;
		font-size: 48px;
		line-height: 53px
	}
	.case-head__tech {
		font-size: 24px;
		line-height: 29px
	}
	.case-head__tech,
	.case-product {
		padding-left: 16px;
		padding-right: 16px
	}
	.case-product {
		margin: 0;
		grid-template-columns: 1fr
	}
	.case-product__title {
		margin-top: 48px;
		margin-bottom: 24px;
		font-size: 28px;
		line-height: 34px
	}
	.case-product__text {
		margin-bottom: 40px;
		max-width: 100%
	}
	.case-product__link {
		padding: 17px 24px;
		font-size: 18px;
		line-height: 22px
	}
	.case-product__link_arrow {
		height: 12px
	}
	.case-product__link:hover .case-product__link_span {
		-webkit-transform: translateX(12px);
		transform: translateX(12px)
	}
	.case-product__image {
		margin-top: 24px
	}
	.case-product__source {
		width: 100%
	}
	.case-techList {
		padding-left: 16px;
		padding-right: 16px;
		grid-template-columns: 1fr;
		grid-column-gap: 64px;
		grid-row-gap: 64px
	}
	.case-techList__title {
		margin-bottom: 16px;
		font-size: 28px;
		line-height: 34px
	}
	.case-techList__text {
		margin-bottom: 16px;
		max-width: 100%
	}
	.case-gallery {
		padding-top: 48px;
		padding-bottom: 95px
	}
	.case-gallery__wrapper {
		padding-left: 16px;
		padding-right: 16px;
		grid-template-columns: auto;
		grid-row-gap: 48px
	}
	.case-gallery__title {
		margin-bottom: 48px;
		font-size: 28px;
		line-height: 34px
	}
	.case-gallery__button {
		font-family: Gilroy, sans-serif;
		font-weight: 700;
		font-size: 18px;
		line-height: 22px;
		color: #fff;
		background-color: hsla(0, 0%, 100%, .1);
		margin: 40px auto 0;
		padding: 11px 28px;
		display: flex;
		border: none;
		outline: none
	}
	.case-next {
		padding-left: 16px;
		padding-right: 16px;
		padding-bottom: 120px
	}
	.case-next__title {
		font-size: 24px;
		line-height: 29px
	}
	.case-next__link {
		font-size: 48px;
		line-height: 53px;
		text-align: center
	}
	.case-more {
		padding: 112px 16px
	}
	.case-more__block {
		width: 100%;
		grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
		grid-column-gap: 24px;
		grid-row-gap: 24px;
		text-align: center
	}
}

@media (max-width:550px) {
	.case.flight-simulator .case-galleryImage,
	.case.plan-b .case-galleryImage {
		grid-template-columns: 1fr
	}
	.case.klt-health .case-head__icon,
	.case.legends-pro .case-head__icon {
		width: 100%
	}
	.case.klt-health .case-galleryImage {
		grid-template-columns: 1fr
	}
}

@media (max-width:370px) {
	.case-techListTools {
		grid-template-columns: repeat(auto-fit, minmax(124px, 1fr))
	}
}

@media (min-width:1140px) {
	.mobile-development .service-tech {
		display: flex;
		max-width: 100%
	}
	.mobile-development .service-tech__block:first-of-type,
	.mobile-development .service-tech__block:nth-of-type(2) {
		margin-right: 24px
	}
	.mobile-development .service-techList {
		grid-template-columns: repeat(2, 1fr)
	}
	.branding-and-ads .service-tech {
		display: flex;
		max-width: 100%
	}
	.branding-and-ads .service-tech__block:first-of-type {
		margin-right: 24px
	}
	.branding-and-ads .service-techList {
		grid-template-columns: repeat(3, 1fr)
	}
}

@media (max-width:1140px) {
	.service {
		padding: 138px 16px 64px
	}
	.service__title {
		font-size: 48px;
		line-height: 53px
	}
	.service__text {
		margin-bottom: 48px;
		max-width: 100%
	}
	.service-tech__block {
		grid-row-gap: 32px
	}
	.service-techList {
		grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
		grid-column-gap: 16px;
		grid-row-gap: 16px
	}
	.service-techList__item {
		width: 100%;
		height: 161px
	}
	.service-techList__name {
		font-size: 18px;
		line-height: 22px
	}
	.service-help {
		margin-top: 32px;
		margin-bottom: 32px;
		grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
		padding-left: 24px;
		grid-column-gap: 14px
	}
}

@media (max-width:1140px) and (max-width:340px) {
	.service-help {
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr))
	}
}

@media (max-width:1140px) {
	.service-help__item {
		width: auto
	}
	.service-link {
		padding: 17px 24px;
		justify-content: space-between;
		font-size: 18px;
		line-height: 22px
	}
	.service-link__arrow {
		height: 12px
	}
	.service-link:hover .service-link__span {
		-webkit-transform: translateX(12px);
		transform: translateX(12px)
	}
	.service-link:hover .service-link__arrow {
		-webkit-transform: none;
		transform: none
	}
}